import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { Container } from '@/components/Container'
import * as styles from './BasicPage.module.scss'

const BasicPage = ({ title, children }) => {
  return (
    <div className={styles.BasicPage}>
      <Container>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.body}>
            <RichText>{children}</RichText>
          </div>
        </div>
      </Container>
    </div>
  )
}

BasicPage.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default BasicPage
