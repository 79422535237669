import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getRichText } from '@/prismic/helpers/getRichText'
import { usePreviewData } from '@/prismic/hooks/usePreviewData'
import BasicPage from '@/templates/BasicPage'

const BasicPagePrismic = ({ data }) => {
  const { prismicBasicPage } = usePreviewData(data)
  const title = prismicBasicPage.data?.title
  const content = getRichText(prismicBasicPage.data?.content?.raw)

  return <BasicPage title={title}>{content}</BasicPage>
}

BasicPagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicPagePrismic

export const pageQuery = graphql`
  query BasicPageByID($id: String!) {
    prismicBasicPage(id: { eq: $id }) {
      data {
        title
        content {
          raw
        }
      }
    }
  }
`
